import gsap from 'gsap';

// Get interface containerby id #container
const container = document.getElementById('container');
// fade in container using gsap
gsap.to(container, { delay: 3, duration: 2, opacity: 1 });

// Fetch user informations from API
const userId = window.location.href.split('/').pop();
fetch(`https://api.teambuildr.fr/api/v1/uo/public/certificate/${userId}`)
    .then(response => response.json())
    .then(res => {
        const fullName = res.data.fullname;
        // Format fullname by lowercasing it and replacing first letter of each word by uppercase
        const formatFullName = fullName.toLowerCase().replace(/(^| )(\w)/g, s => s.toUpperCase());
        document.getElementById('fullname').innerHTML = formatFullName;
        document.getElementById('completionDate').innerHTML = res.data.completionDate;
        document.title += ` - ${formatFullName}`;
    })