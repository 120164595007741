import gsap from 'gsap';
import * as THREE from 'three';
// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { Text } from 'troika-three-text';

/**
 * Global vars
 */

gsap.context(window);
Text.toString()


/**
 * Base
 */
// Canvas
const canvas = document.querySelector('canvas.webgl')
// Scene
const scene = new THREE.Scene()
scene.background = new THREE.Color(0x0D1E53)
scene.fog = new THREE.Fog(0x0D1E53, 10, 15);


/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () => {
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

// Events
/**
 * cursor
 */
const cursor = {
    x: 0,
    y: 0
}
window.addEventListener('mousemove', (e) => {
    cursor.x = e.clientX / sizes.width - 0.5
    cursor.y = - (e.clientY / sizes.height - 0.5)
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 0
camera.position.y = 3
camera.position.z = 3
// camera.rotation.x = -1.82
// camera.rotation.y = -0.77
// camera.rotation.z = -1.92
scene.add(camera)

// Controls
// const controls = new OrbitControls(camera, canvas)
// controls.enableDamping = true

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.shadowMap.enabled = true;
renderer.shadowMap.type = THREE.PCFSoftShadowMap;
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

/**
 * lights
    */
// Ambiant
const ambient = new THREE.AmbientLight(0xFFFFFF, 0.9);
scene.add(ambient);

// Spot light
function createSpotlight(color) {
    const newObj = new THREE.SpotLight(color, 10);
    newObj.rotation.x = - Math.PI * 0.50
    newObj.castShadow = true;
    newObj.angle = 0.9;
    newObj.decay = 1;
    newObj.penumbra = 0.2;
    newObj.distance = 11;

    return newObj;
}
const spotLight1 = createSpotlight(0x333333);
spotLight1.position.set(0, .4, 4);
let lightHelper1 = new THREE.SpotLightHelper(spotLight1);
scene.add(spotLight1);
// scene.add(lightHelper1);
/**
 * Objects
 */

// Plane
const plane = new THREE.Mesh(
    new THREE.PlaneGeometry(109, 109, 5, 5),
    new THREE.MeshPhongMaterial({ color: 0x0D1E53 })
)
plane.rotation.x = - Math.PI * 0.50
// Efect to make plan fade in the distance
plane.receiveShadow = true;

// Import 3D model trimoji.glb from static/models
const gltfLoader = new GLTFLoader()
let logoGlb = null;
gltfLoader.load(
    '/models/trimoji.glb',
    (gltf) => {
        gltf.scene.scale.set(1, 1, 1)
        gltf.scene.position.set(0, 1, 0.5)
        // Change material color of the childs of the scene
        gltf.scene.children[0].material.color = new THREE.Color(0xE5007E)
        gltf.scene.children[1].material.color = new THREE.Color(0xFF9F5A)
        gltf.scene.children[2].material.color = new THREE.Color(0x4396F1)
        // Cast shadow
        gltf.scene.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true
                child.receiveShadow = true
            }
        })
        // Add to scene
        scene.add(gltf.scene)
        camera.lookAt(gltf.scene.position)
        logoGlb = gltf.scene
        // Animate appear
        // Modify y of each gltf child to make them appear one after the other
        gsap.to(logoGlb.children[1].position, {
            duration: 3,
            y: -1,
        })
        gsap.to(logoGlb.children[0].position, {
            duration: 2.5,
            y: -1,
        })
        gsap.to(logoGlb.children[2].position, {
            duration: 1.5,
            delay: .1,
            y: -1,
        })
    })

// Import 3D model achievement.glb from static/models
let achievementGlb = null;
gltfLoader.load(
    '/models/achievement.glb',
    (gltf) => {
        gltf.scene.scale.set(.15, .15, .15)
        gltf.scene.position.set(-1.1, 3.5, -0.10)
        gltf.scene.rotation.x = - Math.PI * 0.50
        gltf.scene.rotation.z = -0.45
        // Cast shadow
        gltf.scene.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true
                child.receiveShadow = true
            }
        })
        // Make children ignore light
        gltf.scene.children[0].material.emissive = new THREE.Color(0x555555)
        // Add to scene
        scene.add(gltf.scene)
        camera.lookAt(gltf.scene.position)
        achievementGlb = gltf.scene
        // Animate whole scene
        gsap.to(achievementGlb.position, {
            duration: 3.5,
            delay: .1,
            x: -1.1,
            y: 0.09,
            z: -0.15,
        })
    })




scene.add(plane)


/**
 * Animate
 */
// Camera transition
gsap.to(camera.position, {
    duration: 5,
    x: 0,
    y: 3,
    z: 2,
})

// Spotlight transition
gsap.to(spotLight1, {
    duration: 5,
    angle: 1,
})


// Ticking
const clock = new THREE.Clock()
const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update camera look at gltf scene
    if (logoGlb !== null) {
        camera.lookAt(logoGlb.children[0].position)
    }

    // Update camera on mouse position using gsap
    if (camera !== null && elapsedTime > 4) {
        gsap.to(camera.position, {
            duration: 3,
            x: Math.sin(cursor.x * Math.PI * .2),
        })

        gsap.to(spotLight1, {
            duration: 5,
            angle: 1 + cursor.x / 3,
        })
    }

    // Render
    renderer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()